.App {
  text-align: center;
  position: relative;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.as-background-default {
  background: #101a24;
}

.as-background-primary {
  background: #101a24;
}

.as-container-medium {
  max-width: 1440px;
}

.as-text-default {
  color: #fff;
}
.as-text-default h1,
.as-text-default h2,
.as-text-default h3,
.as-text-default h4,
.as-text-default h5,
.as-text-default h6,
.as-text-default p,
.as-text-default label {
  color: #fff;
}

.as-text-primary {
  color: #45c428;
}
.as-text-primary h1,
.as-text-primary h2,
.as-text-primary h3,
.as-text-primary h4,
.as-text-primary h5,
.as-text-primary h6,
.as-text-primary p,
.as-text-primary label {
  color: #45c428;
}

/* Value temporarily changed; Previously: #as-secondary-color; */
.as-text-dark {
  color: #000;
}

.as-text-dark h1,
.as-text-dark h2,
.as-text-dark h3,
.as-text-dark h4,
.as-text-dark h5,
.as-text-dark h6,
.as-text-dark p,
.as-text-dark label {
  color: #000;
}

.as-text-small {
  font-size: 12px;
}

.as-text-medium {
  font-size: 18px;
}

.as-text-large {
  font-size: 20px;
}

.as-text-light {
  font-weight: 200;
}

.as-button-small {
  font-size: 12px;
  line-height: 33px;
}

a.as-link.as-text-default,
.uk-nav-primary > li > a.as-link.as-text-default {
  color: #fff;
}
a.as-link.as-text-default:hover,
.uk-nav-primary > li > a.as-link.as-text-default:hover {
  color: #d9d9d9 !important;
  text-decoration: none !important;
}

a.as-link.as-text-primary {
  color: #45c428;
}
a.as-link.as-text-primary:hover {
  color: #369a1f !important;
  text-decoration: none !important;
}

.as-button-primary,
.uk-nav-primary > li > a.as-button-primary {
  border-color: #45c428;
  background: #45c428;
  color: #fff;
  text-transform: none;
}
.as-button-primary:hover,
.as-button-primary:focus,
.as-button-primary:active,
.uk-nav-primary > li > a.as-button-primary:hover,
.uk-nav-primary > li > a.as-button-primary:focus,
.uk-nav-primary > li > a.as-button-primary:active {
  border-color: #3eaf24;
  background-color: #3eaf24;
}
.as-button-primary.as-button-outline,
.uk-nav-primary > li > a.as-button-primary.as-button-outline {
  background: transparent;
  color: #45c428;
}
.as-button-primary.as-button-outline:hover,
.uk-nav-primary > li > a.as-button-primary.as-button-outline:hover {
  color: #3eaf24;
}

.uk-checkbox.as-checkbox-primary {
  min-width: 40px;
  width: 40px;
}
.uk-checkbox.as-checkbox-primary:checked {
  background-color: #45c428;
}
.uk-checkbox.as-checkbox-primary:active,
.uk-checkbox.as-checkbox-primary:focus {
  border-color: #45c428;
}

.as-input:active,
.as-input:focus,
.as-select:active,
.as-select:focus {
  border-color: #45c428;
}

.as-card {
  position: relative;
  height: 454px;
  min-width: 300px;
  margin: 0 40px;
}
.as-card .as-card-body {
  position: absolute;
  bottom: 50px;
}
.as-card .as-card-title {
  color: #45c428;
  border: 2px solid #45c428;
  width: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 150%;
}
.as-card .as-card-text {
  opacity: 0.8;
}

form input.error,
form select.error {
  color: #f0506e !important;
  border-color: #f0506e !important;
}
form label.error {
  color: #f0506e;
  font-size: 80%;
}
form .as-show-if-loading {
  display: none;
}
form .as-hide-if-loading {
  display: inline;
}
form.as-loading .as-show-if-loading {
  display: inline;
}
form.as-loading .as-hide-if-loading {
  display: none;
}

.as-url-input-wrapper input[readonly] {
  font-weight: bold;
}
@media screen and (max-width: 639px) {
  .as-url-input-wrapper input[name='url'] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .as-url-input-wrapper input[readonly] {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media screen and (min-width: 640px) {
  .as-url-input-wrapper input[name='url'] {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .as-url-input-wrapper input[readonly] {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/*# sourceMappingURL=app.css.map */

/*CSS from index.html */
body {
  font-size: 1.7rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.uk-text-small {
  font-size: 1.3rem;
  line-height: 1.5;
}

.uk-margin-medium-left {
  font-size: 1.5rem !important;
  text-decoration: none !important;
}

label > div {
  font-weight: normal !important;
}

label > div > a {
  text-decoration: underline;
}

label {
  font-weight: bold;
}

.h4,
h4 {
  font-weight: 700;
  font-size: 3rem;
}

h4.as-card-subtitle.as-text-default.uk-margin-small {
  font-size: 2rem;
}

.uk-button {
  font-size: 1.75rem;
}

a.as-link.as-text-default.as-text-small {
  font-size: 1.75rem;
}

.as-card .as-card-title {
  line-height: 45px;
  height: 50px;
}

.uk-checkbox.as-checkbox-primary {
  width: 40px;
  height: 40px !important;
}

.uk-transition-scale-up {
  transform: scale3d(0.75, 0.75, 0.75);
}

.as-button-primary:hover,
.as-button-primary:focus,
.as-button-primary:active,
.uk-nav-primary > li > a.as-button-primary:hover,
.uk-nav-primary > li > a.as-button-primary:focus,
.uk-nav-primary > li > a.as-button-primary:active {
  border-color: #78be20;
  background-color: #78be20;
}

a.as-link.as-text-primary {
  color: #78be20;
}

.as-card .as-card-title {
  color: #78be20;
  border: 2px solid #78be20;
}

.as-button-primary,
.uk-nav-primary > li > a.as-button-primary {
  border-color: #78be20;
  background: #78be20;
}

.as-button-primary:hover,
.uk-nav-primary:hover > li > a.as-button-primary:hover {
  border-color: #45c428;
  background: #45c428;
}

a.uk-button.uk-button-default.as-button-primary.as-button-outline.as-button-small.uk-border-rounded:hover {
  background-color: transparent;
}

.as-card .as-card-body {
  bottom: 30px;
}

a.uk-button.uk-button-default.as-button-primary.as-button-outline.as-button-small.uk-border-rounded {
  background-color: transparent;
}

a.uk-button.uk-button-default.uk-button-large.as-button-primary.as-button-outline.uk-border-rounded {
  background-color: transparent;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
}

.react-modal {
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  max-width: 600px;
  z-index: 3;
  opacity: 1;
  background-color: #fff;
  margin: auto;
  overflow: auto;
  max-height: 760px;
  height: 85vh;
}

.react-modal-success {
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  max-width: 600px;
  z-index: 3;
  opacity: 1;
  background-color: #fff;
  margin: auto;
  overflow: auto;
  max-height: 300px;
  height: 85vh;
}

.react-modal-content {
  height: 50vh;
  overflow: auto;
}

.modal-close_button {
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.loading-spinner-large {
  width: 75px;
  height: 75px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  margin-top: 10px;
  margin-bottom: 10px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
